.live-layout .logo {
    padding-right: 16px;
}

.live-layout .logo img {
    height: 45px;
    padding-top: 0px;
    padding-bottom: 5px;
    padding-right: 10px;
}

@media screen and (max-width: 992px) {
    /* xl 992px */
    .logo {
        display: none;
    }
}
