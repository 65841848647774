.logo {
    float: left;
    width: 100px;
    height: 50px;
    margin: 0px 0px 0px 20px;
    filter: invert(100%);
    font-weight: bold;
    font-size: x-large;
    display: table;
}

.logo img {
    height: 45px;
    padding-top: 5px;
    padding-right: 10px;
}

.logo span {
    margin-top: 20px;
    display: table-cell;
    vertical-align: middle;
}

.logo span a:link,
.logo span a:visited,
.logo span a:hover,
.logo span a:active {
    color: inherit;
    /*color: rgba(0, 0, 0, 0.85);*/
    text-decoration: none;
}
